import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Navbar,
  Nav,
  Row,
  Col,
  Table,
  Button,
} from "react-bootstrap";
import "./styles_new.css";

function QualityAssurance() {
  const navigate = useNavigate();

  // Sample static data
  const studentsPerformance = [
    { id: 1, studentName: "Tom Latham", course: "ASE", grade: "A" },
    {
      id: 2,
      studentName: "Tom Latham",
      course: "Database Systems",
      grade: "A",
    },
    { id: 3, studentName: "Tom Latham", course: "CCBD", grade: "A" },
    // ... more data ...
  ];

  const instructorCollaborations = [
    {
      id: 1,
      instructorName: "Kushal Shah",
      department: "Computer Science",
      collaborationType: "Curriculum Development",
    },
    {
      id: 2,
      instructorName: "Kaushal Patel",
      department: "Computer Science",
      collaborationType: "Research Project",
    },
    // ... more data ...
  ];

  const redirectToPage = (page) => {
    navigate(`/${page}`);
  };
  const redirectToLogOut = () => {
    sessionStorage.removeItem("Session_Login_Data");
    navigate("/login");
  };
  const redirectToChat = () => {
    navigate("/chat");
  };
  


  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand>Syllabix</Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
              <Nav.Link>Profile</Nav.Link>
              <Nav.Link onClick={redirectToChat}>Chat</Nav.Link>
              <Nav.Link onClick={redirectToLogOut}>Log Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-4 align-items-center justify-content-center">
        <Row className="w-100">
          <h1>QualityAssuranceOfficer Dashboard</h1>
          <Col className="text-center">
            <div className="d-flex justify-content-center flex-wrap mb-3">
              <Button variant="primary">Contact Student</Button>
              <Button variant="primary">Contact Instructor</Button>
            </div>
            <h2>Student Performance Analysis</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Course</th>
                  <th>Grade</th>
                </tr>
              </thead>
              <tbody>
                {studentsPerformance.map((student) => (
                  <tr key={student.id}>
                    <td>{student.studentName}</td>
                    <td>{student.course}</td>
                    <td>{student.grade}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <h2>Instructor Collaborations</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Instructor Name</th>
                  <th>Department</th>
                  <th>Collaboration Type</th>
                </tr>
              </thead>
              <tbody>
                {instructorCollaborations.map((instructor) => (
                  <tr key={instructor.id}>
                    <td>{instructor.instructorName}</td>
                    <td>{instructor.department}</td>
                    <td>{instructor.collaborationType}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default QualityAssurance;
