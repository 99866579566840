import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Table,
  Card,
} from "react-bootstrap";
import "./styles_new.css";

function Student() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [enrolledCourse, setEnrolledCourse] = useState([]);
  const [users, setUsers] = useState([]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const redirectToHome = () => {
    navigate("/");
  };

  const redirectEnrollment = () => {
    navigate("/classenroll");
  };

  const redirectToLogOut = () => {
    sessionStorage.removeItem("Session_Login_Data");
    navigate("/login");
  };
  const redirectToChat = () => {
    navigate("/chat");
  };

  useEffect(() => {
    fetch("https://kxp7240.uta.cloud/php/getuser.php") // Replace with your endpoint if different
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setUsers(data.users);
        } else {
          console.error("Error fetching users:", data.message);
        }
      })
      .catch((error) => console.error("Error fetching users:", error));
  }, []);

  useEffect(() => {
    fetch("https://kxp7240.uta.cloud/php/enrolledcourse.php") // Replace with your endpoint if different
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setEnrolledCourse(data.enrollments);
        } else {
          console.error("Error fetching enrollments:", data.message);
        }
      })
      .catch((error) => console.error("Error fetching enrollments:", error));
  }, []);

  const filteredEnrollments = enrolledCourse.map((enrollment) => {
    // Find the instructor in the users array
    const instructor = users.find(
      (user) => user.id === enrollment.instructor_id
    );

    // If instructor is found, return the enrollment with additional instructor details
    if (instructor) {
      return {
        ...enrollment,
        instructor_first_name: instructor.first_name,
        instructor_last_name: instructor.last_name,
      };
    }

    // If instructor is not found, return the enrollment as is
    return enrollment;
  });

  console.log(filteredEnrollments);

  return (
    <>
      <Navbar bg="light" expand="lg" className="modern-navbar custom-blue-nav">
        <Container>
          <Navbar.Brand onClick={redirectToHome}>Syllabix</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={redirectToHome}>Home</Nav.Link>
              <Nav.Link>Profile</Nav.Link>
              <Nav.Link onClick={redirectToChat}>Chat</Nav.Link>
              <Nav.Link onClick={redirectToLogOut}>Log Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-4 align-items-center justify-content-center">
        <Row className="w-100">
          <Col className="text-center">
            <h1>Student Dashboard</h1>
            <div className="d-flex justify-content-center flex-wrap mb-3">
              <Button variant="primary" onClick={redirectEnrollment}>
                Class Enrollment
              </Button>
            </div>
            <h3>Courses:</h3>
            <Row>
              {filteredEnrollments &&
                filteredEnrollments.length > 0 &&
                filteredEnrollments.map((v) => (
                  <Col md={4} key={v.course_id}>
                    {" "}
                    {/* Adjust the column size as needed */}
                    <Card className="mb-2">
                      <Card.Body>
                        <Card.Title>{v.subject_name}</Card.Title>
                        <Card.Text>
                          <strong>Instructor:</strong>{" "}
                          {`${v.instructor_first_name} ${v.instructor_last_name}`}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
            <h2>Assignments</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Assignment Name</th>
                  <th>Course</th>
                  <th>Description</th>
                  <th>Due Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Assignment 1</td>
                  <td>ASE</td>
                  <td>Introduction to Software Engineering</td>
                  <td>2023-12-01</td>
                </tr>
                <tr>
                  <td>Assignment 2</td>
                  <td>Database Systems</td>
                  <td>ER Diagrams and SQL Queries</td>
                  <td>2023-12-05</td>
                </tr>
                <tr>
                  <td>Assignment 3</td>
                  <td>CCBD</td>
                  <td>Big Data Basics</td>
                  <td>2023-12-10</td>
                </tr>
              </tbody>
            </Table>

            <h2>Exams</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Exam Name</th>
                  <th>Course</th>
                  <th>Exam Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Exam 1</td>
                  <td>ASE</td>
                  <td>2023-12-15</td>
                </tr>
                <tr>
                  <td>Exam 1</td>
                  <td>Database Systems</td>
                  <td>2023-12-20</td>
                </tr>
                <tr>
                  <td>Quiz 1</td>
                  <td>CCBD</td>
                  <td>2023-12-25</td>
                </tr>
              </tbody>
            </Table>

            <h2>Grades</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Course</th>
                  <th>Grade</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ASE</td>
                  <td>A</td>
                </tr>
                <tr>
                  <td>Database Systems</td>
                  <td>A</td>
                </tr>
                <tr>
                  <td>CCBD</td>
                  <td>A</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Student;
