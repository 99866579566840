import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/HomePage';
import Login from './components/LogIn'
import Signup from './components/signup';
import QualityAssurance from './components/qualityassurance';
import Instructor from './components/instructor';
import ProgramCoordinator from './components/programcoordinator';
import Admin from './components/admin';
import PersonalInformation from './components/PersonalInformation';
import Student from './components/student';
import ForgotPassword from './components/forgetpwd';
import ContactUs from './components/contactus';
import CreateAssignment from './components/Create Assignment';
import CreateExam from './components/CreateExam';
import EditUser from './components/edituser';
import CreateCourse from './components/CreateCourse';
import CourseManage from './components/CourseManage';
import ResourceManage from './components/ResourceManage';
import ClassEnrollment from './components/ClassEnrollment';
import ChatComponent from './components/ChatComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChatAI from './components/ChatAI';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/student" element={<Student />} />
        <Route path="/programcoordinator" element={<ProgramCoordinator />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/qa" element={<QualityAssurance />} />
        <Route path="/instructor" element={<Instructor />} />
        <Route path="/pinfo" element={<PersonalInformation />} />
        <Route path="/forgetpwd" element={<ForgotPassword />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/createassign" element={<CreateAssignment />} />
        <Route path="/createexam" element={<CreateExam />} />
        <Route path="/edituser" element={<EditUser />} />
        <Route path="/createcourse" element={<CreateCourse />} />
        <Route path="/coursemanage" element={<CourseManage />} />
        <Route path="/manageresource" element={<ResourceManage />} />
        <Route path="/classenroll" element={<ClassEnrollment />} />
        <Route path="/chatai" element={<ChatAI />} />
        <Route path="/chat" element={<ChatComponent />} />
      </Routes>
    </Router>
  );
}

export default App;
