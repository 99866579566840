import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Navbar,
  Nav,
  Row,
  Col,
  Table,
  Card,
  Button,
} from "react-bootstrap";
import "./styles_new.css";

function ProgramCoordinator() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [courses, setCourses] = useState([]);

  const loggedInUser = JSON.parse(sessionStorage.getItem("Session_Login_Data"));

  const redirectToInstructor = () => {
    navigate("/instructor");
  };

  const students = [
    {
      id: 1,
      name: "Tom Latham",
      email: "tomlatham@gmail.com",
      phone: "123-456-7890",
      extracurricular: "Cricket",
    },
    {
      id: 2,
      name: "Trust John",
      email: "johntrust123@gmail.com",
      phone: "098-765-4321",
      extracurricular: "Chess Club",
    },
    // ... more students ...
  ];

  useEffect(() => {
    fetch("https://kxp7240.uta.cloud/php/getuser.php") // Replace with your endpoint if different
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setUsers(data.users);
        } else {
          console.error("Error fetching users:", data.message);
        }
      })
      .catch((error) => console.error("Error fetching users:", error));
  }, []);
  useEffect(() => {
    fetch("https://kxp7240.uta.cloud/php/getcourses.php") // Replace with your endpoint if different
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCourses(data.courses);
        } else {
          console.error("Error fetching courses:", data.message);
        }
      })
      .catch((error) => console.error("Error fetching courses:", error));
  }, []);
  const redirectToLogOut = () => {
    sessionStorage.removeItem("Session_Login_Data");
    navigate("/login");
  };
  const redirectToChat = () => {
    navigate("/chat");
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand>Syllabix</Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
              <Nav.Link>Profile</Nav.Link>
              <Nav.Link onClick={redirectToChat}>Chat</Nav.Link>
              <Nav.Link onClick={redirectToLogOut}>Log Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-4 align-items-center justify-content-center">
        <Row className="w-100">
          <Col className="text-center">
            <h1>ProgramCoOrdinator Dashboard</h1>
            <div className="d-flex justify-content-center flex-wrap mb-3">
              <Button variant="primary">Program Evaluation</Button>
              <Button variant="primary">Student Contact</Button>
            </div>

            <h2>Student Details</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Extracurricular Activities</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => (
                  <tr key={student.id}>
                    <td>{student.name}</td>
                    <td>{student.email}</td>
                    <td>{student.phone}</td>
                    <td>{student.extracurricular}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <h2>Courses</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Course</th>
                  <th>Instructor Name</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => {
                  // Find the instructor in the users array
                  const instructor = users.find(
                    (user) => user.id === course.instructor
                  );
                  // Get the instructor's full name, or a placeholder if not found
                  const instructorName = instructor
                    ? `${instructor.first_name} ${instructor.last_name}`
                    : "Instructor Not Found";

                  return (
                    <tr key={course.subject_id}>
                      <td>{course.subject_name}</td>
                      <td>{instructorName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProgramCoordinator;
