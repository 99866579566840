import React, { useState } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';

function PersonalInformation() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
  };

  const redirectToInstructor = () => {
    navigate('/instructor');
  };
  return (
    <div>
      <header>
        <div className="website-title">Syllabix</div>
        <button className="hamburger-menu" onClick={toggleMenu}>
          &#9776; {/* Hamburger icon */}
        </button>
        <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
          <button className="btn" onClick={redirectToInstructor}>
            Instructor Dashboard
          </button>
        </div>
      </header>
      <div className="content-part">
        <div className="form-group">
          <h1>Personal Information</h1>
          <label htmlFor="name">Name</label>
          <span id="name">John Claus</span>
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <span id="email">claus.john@example.com</span>
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <span id="phone">456-789-1230</span>
        </div>
        <h2>Edit Profile</h2>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" required />
        </div>
        <div className="form-group">
          <label htmlFor="newEmail">New Email</label>
          <input type="email" id="email" name="email" required />
        </div>
        <div className="form-group">
          <label htmlFor="newPhone">New Phone</label>
          <input type="text" id="newPhone" name="newPhone" required />
        </div>
        <div className="form-group">
          <button className="btn" type="submit">Save Changes</button>
        </div>
      </div>
    </div>
  );
}

export default PersonalInformation;
 