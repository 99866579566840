import React, { useState, useEffect } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Navbar, Nav } from 'react-bootstrap';

function CreateCourse() {
    const navigate = useNavigate();
    const [instructors, setInstructors] = useState([]);
    const [subjectName, setSubjectName] = useState('');
    const [selectedInstructor, setSelectedInstructor] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        fetchInstructors();
    }, []);

    const fetchInstructors = async () => {
        try {
            const response = await fetch('https://kxp7240.uta.cloud/php/getuser.php');
            if (response.ok) {
                const data = await response.json();
                const instructorList = data.users && data.users.length > 0 && data.users.filter(users => users.role === 'instructor');
                setInstructors(instructorList);
                console.log(instructorList);
            } else {
                console.error('Failed to fetch instructors');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleCourseNameChange = (event) => {
        setSubjectName(event.target.value);
    };    

    const handleInstructorChange = (event) => {
        setSelectedInstructor(event.target.value);
    };

    const createCourse = async (event) => {
        event.preventDefault();

        // Prepare the data to send to the API
        const data = {
            subjectName,
            instructor: selectedInstructor,
        };

        try {
            const response = await fetch('https://kxp7240.uta.cloud/php/coursecreate.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                console.log('Course created successfully!');
                navigate('/admin')
            } else {
                console.error('Failed to create the course.');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    return (
        <div>
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="#">Syllabix</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link onClick={() => navigate('/admin')}>
                            Admin Dashboard
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
    
            <Container className="mt-4 align-items-center justify-content-center">
                <Row className="w-50">
                    <Col className="text-center"> {/* Adjust the size as needed */}
                        <h1>Create Course</h1>
                        <Form onSubmit={createCourse}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="subjectName">Course Name:</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="subjectName"
                                    name="subjectName"
                                    value={subjectName}
                                    onChange={handleCourseNameChange}
                                    required
                                />
                            </Form.Group>
    
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="instructor">Instructor:</Form.Label>
                                <Form.Select
                                    id="instructor"
                                    value={selectedInstructor}
                                    onChange={handleInstructorChange}
                                    required
                                >
                                    <option value="">Select an Instructor</option>
                                    {instructors.map(instructor => (
                                        <option key={instructor.id} value={instructor.id}>
                                            {`${instructor.first_name} ${instructor.last_name}`}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
    
                            <Button variant="primary" type="submit">Create Course</Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default CreateCourse;
