import React, { useState, useEffect } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
    Button,
    Table,
    Form,
  } from "react-bootstrap";

function CreateAssignment() {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [courses, setCourses] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState('');
    const [assignmentName, setAssignmentName] = useState('');
    const [description, setDescription] = useState('');
    const [dueDate, setDueDate] = useState('');
    const loggedInUser = JSON.parse(sessionStorage.getItem("Session_Login_Data"));

    let filteredResult
    if(loggedInUser){
        const result = loggedInUser && loggedInUser.id;
        filteredResult = courses.filter(v => v.instructor === result);
    
    }

    useEffect(() => {
        // Fetch courses when the component loads
        fetch('https://kxp7240.uta.cloud/php/getcourses.php')
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setCourses(data.courses);
                } else {
                    console.error('Error fetching courses:', data.message);
                }
            })
            .catch(error => console.error('Error:', error));
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const redirectToInstructor = () => {
        navigate('/instructor');
    };

    const handleCourseChange = (event) => {
        setSelectedCourse(event.target.value);
    };

    const handleAssignmentNameChange = (event) => {
        setAssignmentName(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleDueDateChange = (event) => {
        setDueDate(event.target.value);
    };

    const createAssignment = async (event) => {
        event.preventDefault();

        const data = {
            instructorId: loggedInUser.id,
            subjectId: parseInt(selectedCourse),
            assignmentName,
            description,
            dueDate,
        };

        try {
            const response = await fetch('https://kxp7240.uta.cloud/php/assignmentcreate.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                console.log('Assignment created successfully!');
                navigate('/instructor');
            } else {
                console.error('Failed to create the assignment.');
            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    // return (
    //     <div>
    //         <header>
    //              <div className="website-title">Syllabix</div>
    //              <button className="hamburger-menu" onClick={toggleMenu}>
    //                  &#9776; {/* Hamburger icon */}
    //              </button>
    //              <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
    //                  <button className="btn" onClick={redirectToInstructor}>
    //                      Instructor Dashboard
    //                  </button>
    //              </div>
    //         </header>
    //         <div className="content-part">
    //             <h1>Create Assignment</h1>
    //             <form onSubmit={createAssignment}>
    //                 <div className="form-group">
    //                     <label htmlFor="courseSelect">Course:</label>
    //                     <select
    //                         id="courseSelect"
    //                         value={selectedCourse}
    //                         onChange={handleCourseChange}
    //                         required
    //                     >
    //                         <option value="">Select a Course</option>
    //                         {filteredResult.map(course => (
    //                             <option key={course.subject_id} value={course.subject_id}>
    //                                 {course.subject_name}
    //                             </option>
    //                         ))}
    //                     </select>
    //                 </div>
    //                 <div className="form-group">
    //                     <label htmlFor="assignment_name">Assignment Name:</label>
    //                     <input type="text" id="assignment_name" name="assignment_name" value={assignmentName} onChange={handleAssignmentNameChange} required />
    //                 </div>
    //                 <div className="form-group">
    //                     <label htmlFor="description">Description:</label>
    //                     <textarea id="description" name="description" value={description} onChange={handleDescriptionChange} required></textarea>
    //                 </div>
    //                 <div className="form-group">
    //                     <label htmlFor="due_date">Due Date:</label>
    //                     <input type="date" id="due_date" name="due_date" value={dueDate} onChange={handleDueDateChange} required />
    //                 </div>
    //                 <div className="form-group">
    //                     <button className="btn" type="submit">Create Assignment</button>
    //                 </div>
    //             </form>
    //         </div>
    //     </div>
    // );
    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand href="#">Syllabix</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link onClick={redirectToInstructor}>
                                Instructor Dashboard
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
    
            <Container className="mt-4 align-items-center justify-content-center">
                <Row className="w-50">
                    <Col className="text-center"> {/* Adjust the column size as needed */}
                        <h1>Create Assignment</h1>
                        <Form onSubmit={createAssignment}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="courseSelect">Course:</Form.Label>
                                <Form.Select
                                    id="courseSelect"
                                    value={selectedCourse}
                                    onChange={handleCourseChange}
                                    required
                                >
                                    <option value="">Select a Course</option>
                                    {filteredResult.map(course => (
                                        <option key={course.subject_id} value={course.subject_id}>
                                            {course.subject_name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
    
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="assignment_name">Assignment Name:</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="assignment_name"
                                    name="assignment_name"
                                    value={assignmentName}
                                    onChange={handleAssignmentNameChange}
                                    required
                                />
                            </Form.Group>
    
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="description">Description:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    id="description"
                                    name="description"
                                    value={description}
                                    onChange={handleDescriptionChange}
                                    required
                                />
                            </Form.Group>
    
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="due_date">Due Date:</Form.Label>
                                <Form.Control
                                    type="date"
                                    id="due_date"
                                    name="due_date"
                                    value={dueDate}
                                    onChange={handleDueDateChange}
                                    required
                                />
                            </Form.Group>
    
                            <Button variant="primary" type="submit">
                                Create Assignment
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
    
}

export default CreateAssignment;
