import React, { useState, useEffect } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
    Form,
    Button,
    Table,
  } from "react-bootstrap";

function ResourceManage() {
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [exams, setExams] = useState([]);
    const [editingAssignment, setEditingAssignment] = useState(null);
    const [editingExam, setEditingExam] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const loggedInUser = JSON.parse(sessionStorage.getItem("Session_Login_Data"));

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const redirectToInstructor = () => {
        navigate('/instructor');
    };

    useEffect(() => {
        fetch('https://kxp7240.uta.cloud/php/getcourses.php')
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setCourses(data.courses);
                } else {
                    console.error('Error fetching courses:', data.message);
                }
            })
            .catch(error => console.error('Error:', error));

        fetch('https://kxp7240.uta.cloud/php/getassignment.php')
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setAssignments(data.assignments);
                } else {
                    console.error('Error fetching assignments:', data.message);
                }
            })
            .catch(error => console.error('Error:', error));

        fetch('https://kxp7240.uta.cloud/php/getexams.php')
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setExams(data.exams);
                } else {
                    console.error('Error fetching exams:', data.message);
                }
            })
            .catch(error => console.error('Error:', error));

        // Fetch instructors...
    }, []);

    // Event handlers and other methods...

    const getCourseNameById = (subject_id) => {
        const course = courses.find(c => c.subject_id === subject_id);
        return course ? course.subject_name : 'Unknown';
    };

    const handleEditAssignment = (assignment) => {
        setEditingAssignment(assignment);
    };

    const handleEditExam = (exam) => {
        setEditingExam(exam);
    };

    const handleAssignmentChange = (event, field) => {
        setEditingAssignment({ ...editingAssignment, [field]: event.target.value });
    };

    const handleExamChange = (event, field) => {
        setEditingExam({ ...editingExam, [field]: event.target.value });
    };

    const submitAssignmentChanges = () => {
        fetch('https://kxp7240.uta.cloud/php/editassignment.php', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(editingAssignment)
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // Update assignments in state
                const updatedAssignments = assignments.map(a => a.assignment_id === editingAssignment.assignment_id ? editingAssignment : a);
                setAssignments(updatedAssignments);
                setEditingAssignment(null);
            } else {
                console.error('Error updating assignment:', data.message);
            }
        })
        .catch(error => console.error('Error:', error));
    };

    const submitExamChanges = () => {
        fetch('https://kxp7240.uta.cloud/php/editexams.php', {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(editingExam)
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // Update exams in state
                const updatedExams = exams.map(e => e.exam_id === editingExam.exam_id ? editingExam : e);
                setExams(updatedExams);
                setEditingExam(null);
            } else {
                console.error('Error updating exam:', data.message);
            }
        })
        .catch(error => console.error('Error:', error));
    };

    return (
        <div>
            <Navbar bg="light" expand="lg">
                <Navbar.Brand href="#">Syllabix</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link onClick={redirectToInstructor}>
                            Instructor Dashboard
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
    
            <Container className="mt-4 align-items-center justify-content-center">
                <Row className="w-100">
                    <Col className="text-center">
                        <h1>Course Management</h1>
    
                        {/* Assignment Table */}
                        <h2>Assignments</h2>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Assignment Name</th>
                                    <th>Course</th>
                                    <th>Description</th>
                                    <th>Due Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {assignments.filter(assignment => assignment.instructor === loggedInUser.id)
                                    .map(assignment => (
                                        <tr key={assignment.assignment_id}>
                                            <td>
                                                {editingAssignment && editingAssignment.assignment_id === assignment.assignment_id ? 
                                                    <Form.Control
                                                        type="text"
                                                        value={editingAssignment.assignment_name} 
                                                        onChange={(e) => handleAssignmentChange(e, 'assignment_name')} 
                                                    /> : 
                                                    assignment.assignment_name
                                                }
                                            </td>
                                            <td>{getCourseNameById(assignment.subject_id)}</td>
                                            <td>
                                                {editingAssignment && editingAssignment.assignment_id === assignment.assignment_id ? 
                                                    <Form.Control
                                                        as="textarea"
                                                        value={editingAssignment.description} 
                                                        onChange={(e) => handleAssignmentChange(e, 'description')} 
                                                    /> : 
                                                    assignment.description
                                                }
                                            </td>
                                            <td>
                                                {editingAssignment && editingAssignment.assignment_id === assignment.assignment_id ? 
                                                    <Form.Control
                                                        type="date" 
                                                        value={editingAssignment.due_date} 
                                                        onChange={(e) => handleAssignmentChange(e, 'due_date')} 
                                                    /> : 
                                                    assignment.due_date
                                                }
                                            </td>
                                            <td>
                                                {editingAssignment && editingAssignment.assignment_id === assignment.assignment_id ? 
                                                    <Button onClick={() => submitAssignmentChanges()}>Save</Button> :
                                                    <Button onClick={() => handleEditAssignment(assignment)}>Edit</Button>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
    
                        {/* Exams Table */}
                        <h2>Exams</h2>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Exam Name</th>
                                    <th>Course</th>
                                    <th>Exam Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {exams.filter(exam => exam.instructor === loggedInUser.id)
                                    .map(exam => (
                                        <tr key={exam.exam_id}>
                                            <td>
                                                {editingExam && editingExam.exam_id === exam.exam_id ? 
                                                    <Form.Control
                                                        type="text"
                                                        value={editingExam.exam_name} 
                                                        onChange={(e) => handleExamChange(e, 'exam_name')} 
                                                    /> : 
                                                    exam.exam_name
                                                }
                                            </td>
                                            <td>{getCourseNameById(exam.subject_id)}</td>
                                            <td>
                                                {editingExam && editingExam.exam_id === exam.exam_id ? 
                                                    <Form.Control
                                                        type="date" 
                                                        value={editingExam.exam_date} 
                                                        onChange={(e) => handleExamChange(e, 'exam_date')} 
                                                    /> : 
                                                    exam.exam_date
                                                }
                                            </td>
                                            <td>
                                                {editingExam && editingExam.exam_id === exam.exam_id ? 
                                                    <Button onClick={() => submitExamChanges()}>Save</Button> :
                                                    <Button onClick={() => handleEditExam(exam)}>Edit</Button>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div>
    );
    
}

export default ResourceManage;
