import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Navbar, Nav, Form, Button } from 'react-bootstrap';
import './styles_new.css';

function ContactUs() {
    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form submitted');
        // Add code to handle form submission
    }

    return (
        <>
            <Navbar bg="light" expand="lg" className="modern-navbar custom-blue-nav">
                <Container>
                    <Navbar.Brand onClick={() => navigate('/')}>Syllabix</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link onClick={() => navigate('/')}>Home</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <Container className="mt-5 d-flex align-items-center justify-content-center">
                <Row className="w-100">
                    <Col md={6} className="mx-auto">
                        <h1>Reach Out To Us</h1>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="name" required />
                            </Form.Group>

                            <Form.Group controlId="formEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" required />
                            </Form.Group>

                            <Form.Group controlId="formPhoneNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="text" name="phonenumber" required />
                            </Form.Group>

                            <Form.Group controlId="formSubject">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control type="text" name="subject" required />
                            </Form.Group>

                            <Form.Group controlId="formMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" name="message" required rows={3} />
                            </Form.Group>

                            <Button variant="primary" type="submit" className="w-100 mt-3">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ContactUs;
