import React, { useState, useEffect } from "react";
import "./styles_new.css";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Form,
  Button,
  Table,
} from "react-bootstrap";

import ChatComponent from './ChatComponent';

function Instructor() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [exams, setExams] = useState([]);
  const [courses, setCourses] = useState([]);
  const loggedInUser = JSON.parse(sessionStorage.getItem("Session_Login_Data"));

  useEffect(() => {
    // Fetch courses, assignments, and exams
    const fetchData = async () => {
      try {
        const courseRes = await fetch("https://kxp7240.uta.cloud/php/getcourses.php");
        const courseData = await courseRes.json();
        if (courseData.success) {
          setCourses(courseData.courses || []);
        }

        const assignmentRes = await fetch(
          "https://kxp7240.uta.cloud/php/getassignment.php"
        );
        const assignmentData = await assignmentRes.json();
        if (assignmentData.success) {
          setAssignments(assignmentData.assignments || []);
        }

        const examRes = await fetch("https://kxp7240.uta.cloud/php/getexams.php");
        const examData = await examRes.json();
        if (examData.success) {
          setExams(examData.exams || []);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  const getCourseNameById = (subject_id) => {
    const course = courses.find((c) => c.subject_id === subject_id);
    return course ? course.subject_name : "Unknown";
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const redirectToAssignmentCreate = () => {
    navigate("/createassign");
  };
  const redirectToExamCreate = () => {
    navigate("/createexam");
  };
  const redirectToResourceManage = () => {
    navigate("/manageresource");
  };

  const redirectToChat = () => {
    navigate("/chat");
  };
  
  const redirectToLogOut = () => {
    sessionStorage.removeItem("Session_Login_Data");
    navigate("/login");
  };

  return (
    <>
      <Navbar bg="light" expand="lg" className="modern-navbar custom-blue-nav">
        <Container>
          <Navbar.Brand onClick={() => navigate("/")}>Syllabix</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
              <Nav.Link>Profile</Nav.Link>
              <Nav.Link onClick={redirectToChat}>Chat</Nav.Link>
              <Nav.Link onClick={redirectToLogOut}>Log Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-4 align-items-center justify-content-center">
        <Row className="w-100">
          <Col className="text-center">
            <h1>Instructor Dashboard</h1>
            <div className="d-flex justify-content-center flex-wrap mb-3">
              <Button variant="primary" onClick={redirectToAssignmentCreate}>
                Assignment Creation
              </Button>{" "}
              <Button variant="primary" onClick={redirectToExamCreate}>
                Exam Creation
              </Button>{" "}
              <Button variant="primary" onClick={redirectToResourceManage}>
                Resource Manage
              </Button>{" "}
              <Button variant="primary">Student Grading</Button>
            </div>
            <h2>Assignments</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Assignment Name</th>
                  <th>Course</th>
                  <th>Description</th>
                  <th>Due Date</th>
                </tr>
              </thead>
              <tbody>
                {assignments
                  .filter(
                    (assignment) => assignment.instructor === loggedInUser.id
                  )
                  .map((assignment) => (
                    <tr key={assignment.assignment_id}>
                      <td>{assignment.assignment_name}</td>
                      <td>{getCourseNameById(assignment.subject_id)}</td>
                      <td>{assignment.description}</td>
                      <td>{assignment.due_date}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <h2>Exams</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Exam Name</th>
                  <th>Course</th>
                  <th>Exam Date</th>
                </tr>
              </thead>
              <tbody>
                {exams
                  .filter((exam) => exam.instructor === loggedInUser.id)
                  .map((exam) => (
                    <tr key={exam.id}>
                      <td>{exam.exam_name}</td>
                      <td>{getCourseNameById(exam.subject_id)}</td>
                      <td>{exam.exam_date}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Instructor;
