import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Table,
  Form,
} from "react-bootstrap";
import "./styles_new.css";

function CreateExam() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [examName, setExamName] = useState("");
  const [examDate, setExamDate] = useState("");

  const loggedInUser = JSON.parse(sessionStorage.getItem("Session_Login_Data"));

  let filteredResult;
  if (loggedInUser) {
    const result = loggedInUser && loggedInUser.id;
    filteredResult = courses.filter((v) => v.instructor === result);
  }

  // Fetch courses when the component loads
  useEffect(() => {
    fetch("https://kxp7240.uta.cloud/php/getcourses.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCourses(data.courses);
        } else {
          console.error("Error fetching courses:", data.message);
        }
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const redirectToInstructor = () => {
    navigate("/instructor");
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  const handleExamNameChange = (event) => {
    setExamName(event.target.value);
  };

  const handleExamDateChange = (event) => {
    setExamDate(event.target.value);
  };

  const createExam = async (event) => {
    event.preventDefault();

    // Prepare the data to send to the API
    const data = {
      instructorId: loggedInUser.id,
      courseID: selectedCourse,
      examName,
      examDate,
    };

    try {
      const response = await fetch("https://kxp7240.uta.cloud/php/examcreate.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // The exam was successfully created.
        console.log("Exam created successfully!");
        navigate("/instructor");
      } else {
        // Handle errors, if any.
        console.error("Failed to create the exam.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">Syllabix</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={redirectToInstructor}>
                Instructor Dashboard
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className="mt-4 align-items-center justify-content-center">
        <Row className="w-50">
          <Col className="text-center">
            <h1>Create Exam</h1>
            <Form onSubmit={createExam}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="courseSelect">Course:</Form.Label>
                <Form.Select
                  id="courseSelect"
                  value={selectedCourse}
                  onChange={handleCourseChange}
                  required
                >
                  <option value="">Select a Course</option>
                  {filteredResult.map((course) => (
                    <option key={course.subject_id} value={course.subject_id}>
                      {course.subject_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="examName">Exam Name:</Form.Label>
                <Form.Control
                  type="text"
                  id="examName"
                  name="examName"
                  value={examName}
                  onChange={handleExamNameChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="examDate">Exam Date:</Form.Label>
                <Form.Control
                  type="date"
                  id="examDate"
                  name="examDate"
                  value={examDate}
                  onChange={handleExamDateChange}
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit">
                Create Exam
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CreateExam;
