import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

function ChatAI() {
    const [userInput, setUserInput] = useState("");
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(false);

    const OPENAI_API_KEY = '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'; // Put your API key here
    const OPENAI_ENDPOINT = 'https://api.openai.com/v1/engines/davinci/completions';

    const sendMessage = async () => {
        if (!userInput.trim()) return;
        addMessage("You", userInput);
        setUserInput("");
        setLoading(true);

        try {
            const response = await axios.post(
                OPENAI_ENDPOINT,
                {
                    prompt: userInput,
                    max_tokens: 75,
                    temperature: 0.5,
                    n: 1,
                    stop: null,
                    frequency_penalty: 0.5,
                    presence_penalty: 0.5, 
                    best_of: 1
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${OPENAI_API_KEY}`
                    }
                }
            );

            const botResponse = response.data.choices[0]?.text.trim() || 'I have no response.';
            addMessage("Bot", botResponse);
            setLoading(false);
        } catch (error) {
            console.error('Error calling GPT-3 API:', error);
            addMessage("Bot", 'Sorry, an error occurred. Please try again.');
            setLoading(false);
        }
    };

    const addMessage = (sender, message) => {
        setMessages((prevMessages) => [...prevMessages, { sender, message }]);
    };

    const handleUserInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            sendMessage();
        }
    };

    // Simulating the voice recognition functionality (this is a placeholder)
    const startVoiceRecognition = () => {
        // Check if the browser supports SpeechRecognition
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            console.error("Your browser does not support SpeechRecognition.");
            return;
        }
    
        // Create a new instance of SpeechRecognition
        const recognition = new SpeechRecognition();
        recognition.lang = 'en-US';
        recognition.interimResults = false; // We only want final results
    
        recognition.onstart = () => {
            console.log("Voice recognition started. Speak into the microphone.");
        };
    
        recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            console.log("Transcript: ", transcript);
            setUserInput(transcript); // Update the user input with the voice input
            sendMessage(); // Optionally, send the message right after speech is recognized
        };
    
        recognition.onerror = (event) => {
            console.error("Voice recognition error:", event.error);
        };
    
        recognition.onend = () => {
            console.log("Voice recognition ended.");
        };
    
        // Start the voice recognition
        recognition.start();
    };
    
    // return (
    //     <div className="chatbot-container">
    //         <div className="chat-icon" onClick={() => {}}>
    //             <img src="chatbot-icon.png" alt="Chat Icon" />
    //         </div>

    //         <div className="chatbox">
    //             <div className="chat-title">Chatbot🤖</div>
    //             <div className="close-chat" onClick={() => {}}>X</div>
    //             <div className="chat-display">
    //                 {messages.map((msg, index) => (
    //                     <div key={index} className={`${msg.sender}-message`}>
    //                         {`${msg.sender}: ${msg.message}`}
    //                     </div>
    //                 ))}
    //             </div>
    //             {loading && <div className="loading-indicator">Loading...</div>}
    //             <input
    //                 type="text"
    //                 value={userInput}
    //                 onChange={handleUserInputChange}
    //                 onKeyPress={handleKeyPress}
    //                 placeholder="Type your message..."
    //             />
    //             <button onClick={sendMessage}>Send</button>
    //             <img
    //                 src="microphone-icon.png"
    //                 alt="Microphone"
    //                 onClick={startVoiceRecognition}
    //             />
    //         </div>
    //     </div>
    // );
    return (
        <Container className="chatbot-container p-3">
            <Row>
                <Col xs={12} className="text-center">
                    <div className="chat-icon" onClick={() => {}}>
                        <img src="chatbot-icon.png" alt="Chat Icon" />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <div className="chatbox p-3">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div className="chat-title">Chatbot🤖</div>
                            <div className="close-chat" onClick={() => {}}>X</div>
                        </div>
                        <div className="chat-display mb-2">
                            {messages.map((msg, index) => (
                                <div key={index} className={`${msg.sender}-message`}>
                                    {`${msg.sender}: ${msg.message}`}
                                </div>
                            ))}
                        </div>
                        {loading && <div className="loading-indicator">Loading...</div>}
                        <Form>
                            <Form.Group className="mb-2" controlId="userInput">
                                <Form.Control
                                    type="text"
                                    value={userInput}
                                    onChange={handleUserInputChange}
                                    onKeyPress={handleKeyPress}
                                    placeholder="Type your message..."
                                />
                            </Form.Group>
                            <Button onClick={sendMessage}>Send</Button>
                            <img
                                src="microphone-icon.png"
                                alt="Microphone"
                                onClick={startVoiceRecognition}
                                className="ms-2"
                            />
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ChatAI;
