import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Form,
  Button,
  Alert,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import "./styles_new.css"; // Ensure this CSS file contains the Bootstrap overrides if needed

function LogIn() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [role, setRole] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setErrorMessage("");

    const email = e.target.username.value; // Get the email input value
    const password = e.target.password.value; // Get the password input value

    try {
      // Perform the login process by making a POST request to your PHP login API
      const response = await fetch("https://kxp7240.uta.cloud/php/login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          // Login successful, set the role and mark as logged in
          setRole(data.role);
          setLoggedIn(true);
          sessionStorage.setItem("Session_Login_Data", JSON.stringify(data));
          console.log("Role:", data.role); // Add this line

          let sessionResult = sessionStorage.getItem("Session_Login_Data");

          if (sessionResult !== null || sessionResult !== undefined) {
            if (data.role === "student") {
              navigate("/student");
            } else if (data.role === "instructor") {
              navigate("/instructor");
            } else if (data.role === "administrator") {
              navigate("/admin");
            } else if (data.role === "programCoordinator") {
              navigate("/programcoordinator");
            } else if (data.role === "qaOfficer") {
              navigate("/qa");
            } else {
              navigate("/");
            }
            // redirectToProfile(data.role); // Redirect to the profile page after login
          } else {
            setErrorMessage(data.message || "Login failed. Please try again.");
          }
        } else {
          setErrorMessage(data.message || "An error occurred. Please try again.");
        }
      } 
    } catch (error) {
      setErrorMessage(error.message || "An unexpected error occurred.");
    }
  };

  return (
    <>
      <Navbar bg="light" expand="lg" className="modern-navbar custom-blue-nav">
        <Container>
          <Navbar.Brand>Syllabix</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="min-v-100 d-flex mt-5 align-items-center justify-content-center">
        <Row className="w-100">
          <Col md={6} className="mx-auto">
            <h1>Login</h1>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form onSubmit={handleLogin}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="username"
                  placeholder="Enter email"
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="w-100">
                Log In
              </Button>

              <div className="mt-4 text-center">
                <Link to="/forgetpwd">Forgot your password?</Link>
              </div>

              <div className="mt-4 text-center">
                <Button
                  variant="secondary"
                  onClick={() => navigate("/signup")}
                  className="w-100"
                >
                  Sign Up
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default LogIn;
