import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ChatComponent.css"; // Import the CSS file
import axios from "axios";
import {
    Container,
    Row,
    Col,
    Navbar,
    Nav,
    Form,
    FormControl,
    ListGroup,
    Alert,
    Button,
    Table,
  } from "react-bootstrap";

const ChatComponent = () => {

    const userid = JSON.parse(sessionStorage.getItem("Session_Login_Data")).id
   
    const [chatList, setChatList] = useState([]);
    const [chatData, setChatData] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [searchEmail, setSearchEmail] = useState("");
    const [newMessage, setNewMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const redirectToHome = () => {
        navigate("/");
    };

    const redirectToChat = () => {
        navigate("/chat");
    };

    const redirectToLogOut = () => {
        sessionStorage.removeItem("Session_Login_Data");
        navigate("/login");
    };

    const api = axios.create({
        baseURL: "http://localhost:3002",
        withCredentials: true,
    });

    useEffect(() => {
        // Fetch chat list when the component mounts
        fetchChatList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userid]);

    useEffect(() => {
        // Fetch chat history when the selected chat changes
        if (selectedChat) {
             // eslint-disable-next-line react-hooks/exhaustive-deps
             fetchChatHistory();
        }
    }, [selectedChat]);

    const fetchChatList = async () => {
        try {
            console.log(userid)
            const response = await api.get(`/chat-list/${userid}`);

            setChatList(response.data);
            // Set the latest chat as the default selected chat
            if (response.data.length > 0) {
                setSelectedChat(response.data[0]);
            }
        } catch (error) {
            console.error("Error fetching chat list:", error);
        }
    };

    const fetchChatHistory = async () => {
        try {
            setLoading(true);
            const response = await api.get(
                `/chat-history/${userid}/${selectedChat.receiver_email}`
            );
            setChatData(response.data);
        } catch (error) {
            console.error("Error fetching chat history:", error);
            setError("Error fetching chat history. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = () => {
        const searchEndpoint = `/search-user?email=${searchEmail}`;

        api.get(searchEndpoint)
            .then((response) => {
                const data = response.data;
                if (data.userFound) {
                    const userId = data.userId;

                    // Find the user in the chatList
                    const foundUser = chatList.find(
                        (user) => String(user.receiver_email) === String(searchEmail)
                    );

                    // If the user is found, set it as the selected chat
                    if (foundUser) {
                        setSelectedChat(foundUser);
                    } else {
                        // If the user is not in the current chatList, add them to the list
                        setChatList([
                            ...chatList,
                            { userid: userId, receiver_email: searchEmail },
                        ]);
                        // Set the searched user as the selected chat
                        setSelectedChat({
                            userid: userId,
                            receiver_email: searchEmail,
                        });
                    }
                } else {
                    console.log("User not found");
                }
            })
            .catch((error) => {
                console.error("Error searching for user:", error);
            });
    };

    const handleSendMessage = async () => {
        try {
            setLoading(true);
            await api.post("/send-message", {
                sender_id: userid,
                receiver_email: selectedChat.receiver_email,
                content: newMessage,
            });
            // After sending the message, fetch updated chat history
            fetchChatHistory();
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // Clear the input field
            setNewMessage("");
        } catch (error) {
            console.error("Error sending message:", error);
            setError("Error sending message. Please try again later.");
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand>Syllabix</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link onClick={redirectToHome}>Home</Nav.Link>
                            <Nav.Link onClick={redirectToChat}>Chat</Nav.Link>
                            <Nav.Link onClick={redirectToLogOut}>Log Out</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
    
            <Container className="mt-4 align-items-center justify-content-center">
                <Row className="w-100">
                    <Col md={10}>
                        <div className="chat-list">
                            <h1>Chat List</h1>
                            <div className="mb-3">
                                <FormControl
                                    type="text"
                                    value={searchEmail}
                                    onChange={(e) => setSearchEmail(e.target.value)}
                                    placeholder="Enter user email"
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            handleSearch();
                                        }
                                    }}
                                />
                                <Button onClick={handleSearch}>Search</Button>
                            </div>
                            <ListGroup>
                                {chatList.map((chat) => (
                                    <ListGroup.Item
                                        key={chat.receiver_email}
                                        action
                                        onClick={() => setSelectedChat(chat)}
                                        className={chat.receiver_email === selectedChat?.receiver_email ? 'active' : ''}
                                    >
                                        {chat.receiver_email}
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>
                    </Col>
                    <Col md={8}>
                        <div className="chat-container">
                            {selectedChat ? (
                                <div>
                                    <h2>
                                        <strong>{selectedChat.receiver_email}</strong>
                                    </h2>
                                    {loading && <p>Loading...</p>}
                                    {error && <Alert variant="danger">{error}</Alert>}
                                    <div className="message-container">
                                        {chatData.map((message) => (
                                            <div
                                                key={message.message_id}
                                                className={`chat-message ${
                                                    String(message.sender_id) === String(userid)
                                                        ? "your-message"
                                                        : "receiver-message"
                                                }`}
                                            >
                                                <strong>
                                                    {String(message.sender_id) === String(userid)
                                                        ? "You"
                                                        : `${selectedChat.receiver_email}`}
                                                </strong>
                                                : {message.content}
                                            </div>
                                        ))}
                                    </div>
    
                                    <Form className="d-flex mt-3">
                                        <FormControl
                                            type="text"
                                            value={newMessage}
                                            onChange={(e) =>
                                                setNewMessage(e.target.value)
                                            }
                                            placeholder="Type your message..."
                                            className="me-2"
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    handleSendMessage();
                                                }
                                            }}
                                        />
                                        <Button
                                            type="submit"
                                            onClick={handleSendMessage}
                                        >
                                            Send
                                        </Button>
                                    </Form>
                                </div>
                            ) : (
                                <p>No chat selected</p>
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
    

    // return (
    //     <>
    //         <header>
    //             <div className="website-title">Syllabix</div>
    //             <button className="hamburger-menu" onClick={toggleMenu}>
    //                 &#9776; {/* Hamburger icon */}
    //             </button>
    //             <div className={`header-buttons ${isMenuOpen ? "open" : ""}`}>
    //                 <button className="btn" onClick={redirectToHome}>
    //                     Home
    //                 </button>
    //                 <button className="btn" onClick={redirectToChat}>
    //                     Chat
    //                 </button>
    //                 <button className="btn" onClick={redirectToLogOut}>
    //                     Log Out
    //                 </button>
    //             </div>
    //         </header>
    //         <div className="chat-component">
    //             <div className="chat-list">
    //                 <h1>Chat List</h1>
    //                 <div>
    //                     <input
    //                         type="text"
    //                         value={searchEmail}
    //                         onChange={(e) => setSearchEmail(e.target.value)}
    //                         placeholder="Enter user email"
    //                         onKeyDown={(e) => {
    //                             if (e.key === "Enter") {
    //                                 handleSearch();
    //                             }
    //                         }}
    //                     />
    //                     <button onClick={handleSearch}>Search</button>
    //                 </div>
    //                 <ul>
    //                     {chatList.map((chat) => (
    //                         <li key={chat.receiver_email}>
    //                             <div
    //                                 onClick={() => setSelectedChat(chat)}
    //                                 className={`chat-list-item ${chat.receiver_email === selectedChat?.receiver_email ? 'selected-chat' : ''}`}
    //                             >
    //                                 <strong>{chat.receiver_email}</strong>
    //                             </div>
    //                         </li>
    //                     ))}
    //                 </ul>
    //             </div>
    //             <div className="chat-container">
    //                 {selectedChat ? (
    //                     <div>
    //                         <h2>
    //                             <strong>{selectedChat.receiver_email}</strong>
    //                         </h2>
    //                         {loading && <p>Loading...</p>}
    //                         {error && <p className="chat-error">{error}</p>}
    //                         <div className="message-container">
    //                             {chatData.map((message) => (
    //                                 <div
    //                                     key={message.message_id}
    //                                     className={`chat-message ${
    //                                         String(message.sender_id) === String(userid)
    //                                             ? "your-message"
    //                                             : "receiver-message"
    //                                     }`}
    //                                 >
    //                                     <strong>
    //                                         {String(message.sender_id) === String(userid)
    //                                             ? "You"
    //                                             : `${selectedChat.receiver_email}`}
    //                                     </strong>
    //                                     : {message.content}
    //                                 </div>
    //                             ))}
    //                         </div>

    //                         <div className="input-container">
    //                             <input
    //                                 type="text"
    //                                 value={newMessage}
    //                                 onChange={(e) =>
    //                                     setNewMessage(e.target.value)
    //                                 }
    //                                 placeholder="Type your message..."
    //                                 className="input-field"
    //                                 onKeyDown={(e) => {
    //                                     if (e.key === "Enter") {
    //                                         handleSendMessage();
    //                                     }
    //                                 }}
    //                             />
    //                             <button
    //                                 type="submit"
    //                                 className="send-button"
    //                                 onClick={handleSendMessage}
    //                             >
    //                                 Send
    //                             </button>
    //                         </div>
    //                     </div>
    //                 ) : (
    //                     <p>No chat selected</p>
    //                 )}
    //             </div>
    //         </div>
    //     </>
    // );
};

export default ChatComponent;
