import React, { useState, useEffect } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';
import { Container, Table, Button, Form } from 'react-bootstrap';

function EditUser() {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [editingUser, setEditingUser] = useState(null);

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        fetch('https://kxp7240.uta.cloud/php/getuser.php')
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setUsers(data.users);
                } else {
                    console.error('Error fetching users:', data.message);
                }
            })
            .catch(error => console.error('Network error:', error));
    }, []);

    const handleEdit = (user) => {
        setEditingUser(user);
    };

    const handleRemove = (userId) => {
        // Placeholder for removing user.
    };

    const handleSubmitChanges = (updatedUser) => {
        fetch('https://kxp7240.uta.cloud/php/edituser.php', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedUser)
        })
        .then(response => response.json())
        .then(data => {
            if (data.success) {
                // Locate the user in the 'users' array and replace with the updated user.
                const updatedUsersList = users.map(user => user.id === updatedUser.id ? updatedUser : user);
                setUsers(updatedUsersList);
            } else {
                console.error('Error updating user:', data.message);
            }
        })
        .catch(error => console.error('Network error:', error));
    
        setEditingUser(null);
    };
    

    return (
        <div>
    <header>
              <div className="website-title">Syllabix</div>
                <button className="hamburger-menu" onClick={toggleMenu}>
                  &#9776; {/* Hamburger icon */}
                </button>
              <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
                <button className="btn" onClick={() => navigate('/admin')}>
                  AdminDashboard
                </button>
              </div>
            </header>
    <div className="dashboard">
        <h1>UserData</h1>
        <table>
            <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Address</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Zipcode</th>
                    <th>Role</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {users.map(user => (
                    <tr key={user.id}>
                        <td>
                            {editingUser && editingUser.id === user.id ? 
                                <input 
                                    defaultValue={user.first_name} 
                                    onChange={(e) => {
                                        const updatedUser = {...editingUser, first_name: e.target.value};
                                        setEditingUser(updatedUser);
                                    }} 
                                /> : 
                                user.first_name
                            }
                        </td>
                        <td>
                            {editingUser && editingUser.id === user.id ? 
                                <input 
                                    defaultValue={user.last_name}
                                    onChange={(e) => {
                                        const updatedUser = {...editingUser, last_name: e.target.value};
                                        setEditingUser(updatedUser);
                                    }}
                                /> : 
                                user.last_name
                            }
                        </td>
                        <td>
                            {editingUser && editingUser.id === user.id ? 
                                <input 
                                    defaultValue={user.email}
                                    onChange={(e) => {
                                        const updatedUser = {...editingUser, email: e.target.value};
                                        setEditingUser(updatedUser);
                                    }}
                                /> : 
                                user.email
                            }
                        </td>

                        <td>
                            {editingUser && editingUser.id === user.id ? 
                                <input 
                                    defaultValue={user.street_address}
                                    onChange={(e) => {
                                        const updatedUser = {...editingUser, street_address: e.target.value};
                                        setEditingUser(updatedUser);
                                    }}
                                /> : 
                                user.street_address
                            }
                        </td>
                        <td>
                            {editingUser && editingUser.id === user.id ? 
                                <input 
                                    defaultValue={user.city}
                                    onChange={(e) => {
                                        const updatedUser = {...editingUser, city: e.target.value};
                                        setEditingUser(updatedUser);
                                    }}
                                /> : 
                                user.city
                            }
                        </td>
                        <td>
                            {editingUser && editingUser.id === user.id ? 
                                <input 
                                    defaultValue={user.state}
                                    onChange={(e) => {
                                        const updatedUser = {...editingUser, state: e.target.value};
                                        setEditingUser(updatedUser);
                                    }}
                                /> : 
                                user.state
                            }
                        </td>
                        <td>
                            {editingUser && editingUser.id === user.id ? 
                                <input 
                                    defaultValue={user.zipcode}
                                    onChange={(e) => {
                                        const updatedUser = {...editingUser, zipcode: e.target.value};
                                        setEditingUser(updatedUser);
                                    }}
                                /> : 
                                user.zipcode
                            }
                        </td>
                        <td>
                            {editingUser && editingUser.id === user.id ? 
                                <select 
                                    value={editingUser.role}
                                    onChange={(e) => {
                                    const updatedUser = {...editingUser, role: e.target.value};
                                    setEditingUser(updatedUser);
                                    }}
                                >
                                    <option value="student">Student</option>
                                    <option value="instructor">Instructor</option>
                                    <option value="administrator">Administrator</option>
                                    <option value="qaOfficer">QA Officer</option>
                                    <option value="programCoordinator">Program Coordinator</option>
                                </select> : 
                                user.role
                            }
                        </td>

                        <td>
                            {editingUser && editingUser.id === user.id ? 
                                <button onClick={() => handleSubmitChanges(editingUser)}>Submit</button> : 
                                <button onClick={() => handleEdit(user)}>Edit</button>}
                            <button onClick={() => handleRemove(user.id)}>Remove</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
</div>

    );
}

export default EditUser;
