import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Navbar,
  Nav,
  Form,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import "./styles_new.css";

function ForgotPassword() {
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate("/");
  };
  const redirectToLogin = () => {
    navigate("/login");
  };
  const redirectToSignUp = () => {
    navigate("/signup");
  };

  return (
    <>
      <Navbar bg="light" expand="lg" className="modern-navbar custom-blue-nav">
        <Container>
          <Navbar.Brand onClick={redirectToHome}>Syllabix</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={redirectToHome}>Home</Nav.Link>
              <Nav.Link onClick={redirectToLogin}>LogIn</Nav.Link>
              <Nav.Link onClick={redirectToSignUp}>Sign Up</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="mt-5 d-flex align-items-center justify-content-center">
        <Row className="w-100">
          <Col md={6} className="mx-auto">
            <h1>Forgot Password</h1>
            <Form>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Enter Email Address</Form.Label>
                <Form.Control type="email" name="email" required />
              </Form.Group>
              <Button variant="primary" type="submit" className="w-100 mt-3">
                Reset Password
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ForgotPassword;
