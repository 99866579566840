import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Table,
  Form,
} from "react-bootstrap";
import "./styles_new.css";

function ClassEnrollment() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedInstructor, setSelectedInstructor] = useState("");
  const [studentId, setStudentId] = useState(""); // Assuming you have a way to get the student's ID
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const loggedInUser = JSON.parse(sessionStorage.getItem("Session_Login_Data"));

  useEffect(() => {
    // Fetch courses
    fetch("https://kxp7240.uta.cloud/php/getcourses.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCourses(data.courses);
        } else {
          console.error("Error fetching courses:", data.message);
        }
      })
      .catch((error) => console.error("Error:", error));

    // Fetch instructors
    fetch("https://kxp7240.uta.cloud/php/getuser.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          let resultData = data.users.filter(
            (user) => user.role === "instructor"
          );
          setInstructors(resultData);
          console.log(instructors);
        } else {
          console.error("Error fetching instructors:", data.message);
        }
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const redirectToStudent = () => {
    navigate("/student");
  };

  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
    setSelectedInstructor(""); // Reset instructor selection
  };

  const getInstructorOptions = () => {
    // Find all course entries that match the selected course name
    const selectedCourseEntries = courses.filter(
      (course) => course.subject_name === selectedCourse
    );

    // Gather instructor IDs from these course entries
    const instructorIds = selectedCourseEntries.map(
      (course) => course.instructor
    );
    // Filter instructors based on these IDs and map them to select options
    const instructorOptions = instructors
      .filter((instructor) => instructorIds.includes(instructor.id))
      .map((instructor) => (
        <option key={instructor.id} value={instructor.id}>
          {`${instructor.first_name} ${instructor.last_name}`}
        </option>
      ));

    return instructorOptions.length > 0 ? (
      instructorOptions
    ) : (
      <option value="">No instructors available</option>
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const enrollmentData = {
      subjectId: selectedCourse,
      instructorId: parseInt(selectedInstructor),
      studentId: loggedInUser && loggedInUser.id, // Assuming studentId is available in the component's state
    };

    try {
      const response = await fetch("https://kxp7240.uta.cloud/php/enrollment.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(enrollmentData),
      });

      if (response.ok) {
        console.log("Enrollment successful!");
        navigate("/student"); // Replace with your desired route
      } else {
        console.error("Failed to enroll in the course.");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">Syllabix</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={redirectToStudent}>
                Student Dashboard
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className="mt-4 align-items-center justify-content-center">
        <Row className="w-50">
          <Col className="text-center">
            <h1>Class Enrollment</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="courseSelect">Course:</Form.Label>
                <Form.Select
                  id="courseSelect"
                  value={selectedCourse}
                  onChange={handleCourseChange}
                  required
                >
                  <option value="">Select a Course</option>
                  {courses.map((course) => (
                    <option key={course.subject_id} value={course.subject_name}>
                      {course.subject_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="instructorSelect">Instructor:</Form.Label>
                <Form.Select
                  id="instructorSelect"
                  value={selectedInstructor}
                  onChange={(e) => setSelectedInstructor(e.target.value)}
                  required
                >
                  <option value="">Select an Instructor</option>
                  {getInstructorOptions()}
                </Form.Select>
              </Form.Group>

              <Button variant="primary" type="submit">
                Enroll
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ClassEnrollment;
