import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Table,
  Form,
} from "react-bootstrap";
import "./styles_new.css";

function CourseManage() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [editingCourse, setEditingCourse] = useState(null);
  const [instructors, setInstructors] = useState([]);
  const [selectedInstructor, setSelectedInstructor] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    fetchInstructors();
  }, []);

  const fetchInstructors = async () => {
    try {
      const response = await fetch("https://kxp7240.uta.cloud/php/getuser.php");
      if (response.ok) {
        const data = await response.json();
        const instructorList =
          data.users &&
          data.users.length > 0 &&
          data.users.filter((users) => users.role === "instructor");
        setInstructors(instructorList);
        console.log(instructorList);
      } else {
        console.error("Failed to fetch instructors");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    fetch("https://kxp7240.uta.cloud/php/getcourses.php")
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCourses(data.courses);
        } else {
          console.error("Error fetching courses:", data.message);
        }
      })
      .catch((error) => console.error("Network error:", error));
  }, []);

  const handleEdit = (course) => {
    setEditingCourse(course);
  };
  const handleInstructorChange = (event) => {
    setSelectedInstructor(event.target.value);
  };

  const handleSubmitChanges = (updatedCourse) => {
    fetch("https://kxp7240.uta.cloud/php/editcourse.php", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedCourse),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Update the courses array with the updated course details
          const updatedCoursesList = courses.map((course) =>
            course.subject_id === updatedCourse.subject_id
              ? updatedCourse
              : course
          );
          setCourses(updatedCoursesList);

          // Optionally, you can add a success message or handle UI changes
          console.log("Course updated successfully!");
        } else {
          // Handle the error scenario, perhaps show an error message
          console.error("Error updating course:", data.message);
        }
      })
      .catch((error) => {
        // Handle network errors
        console.error("Network error:", error);
      });

    // Reset the editing state
    setEditingCourse(null);
  };

  // return (
  //     <div>
  //         <header>
  //             <div className="website-title">Syllabix</div>
  //                 <button className="hamburger-menu" onClick={toggleMenu}>
  //                 &#9776; {/* Hamburger icon */}
  //                 </button>
  //             <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
  //                 <button className="btn" onClick={() => navigate('/admin')}>
  //                 AdminDashboard
  //                 </button>
  //             </div>
  //         </header>
  //         <div className="dashboard">
  //             <h1>Course Management</h1>
  //             <table>
  //                 <thead>
  //                     <tr>
  //                         <th>Course Name</th>
  //                         <th>Instructor ID</th>
  //                         <th>Action</th>
  //                     </tr>
  //                 </thead>
  //                 <tbody>
  //                     {courses.map(course => {
  //                         // Find the instructor in the instructors array
  //                         const instructor = instructors.find(user => user.id === parseInt(course.instructor));

  //                         // Get the instructor's full name, or a placeholder if not found
  //                         const instructorName = instructor
  //                             ? `${instructor.first_name} ${instructor.last_name}`
  //                             : 'Instructor Not Found';

  //                         return (
  //                             <tr key={course.subject_id}>
  //                                 <td>
  //                                     {editingCourse && editingCourse.subject_id === course.subject_id ?
  //                                         <input
  //                                             defaultValue={course.subject_name}
  //                                             onChange={(e) => setEditingCourse({ ...editingCourse, subject_name: e.target.value })}
  //                                         /> :
  //                                         course.subject_name
  //                                     }
  //                                 </td>
  //                                 <td>
  //                                     {editingCourse && editingCourse.subject_id === course.subject_id ?
  //                                         <select
  //                                             id="instructor"
  //                                             value={editingCourse.instructor}
  //                                             onChange={(e) => setEditingCourse({ ...editingCourse, instructor: e.target.value })}
  //                                             required
  //                                         >
  //                                             <option value="">Select an Instructor</option>
  //                                             {instructors.map(instructor => (
  //                                                 <option key={instructor.id} value={instructor.id}>
  //                                                     {`${instructor.first_name} ${instructor.last_name}`}
  //                                                 </option>
  //                                             ))}
  //                                         </select> :
  //                                         instructorName
  //                                     }
  //                                 </td>
  //                                 <td>
  //                                     {editingCourse && editingCourse.subject_id === course.subject_id ?
  //                                         <button onClick={() => handleSubmitChanges(editingCourse)}>Submit</button> :
  //                                         <button onClick={() => {
  //                                             handleEdit(course);
  //                                             setSelectedInstructor(course.instructor);
  //                                         }}>Edit</button>}
  //                                 </td>
  //                             </tr>
  //                         );
  //                     })}
  //                 </tbody>

  //             </table>
  //         </div>
  //     </div>
  // );
  return (
    <>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="#">Syllabix</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => navigate("/admin")}>
                AdminDashboard
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className="mt-4 align-items-center justify-content-center">
        <Row className="w-100">
          <Col className="text-center">
            <h1>Course Management</h1>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Course Name</th>
                  <th>Instructor ID</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => {
                  // Find the instructor in the instructors array
                  const instructor = instructors.find(
                    (user) => user.id === parseInt(course.instructor)
                  );

                  // Get the instructor's full name, or a placeholder if not found
                  const instructorName = instructor
                    ? `${instructor.first_name} ${instructor.last_name}`
                    : "Instructor Not Found";

                  return (
                    <tr key={course.subject_id}>
                      <td>
                        {editingCourse &&
                        editingCourse.subject_id === course.subject_id ? (
                          <input
                            defaultValue={course.subject_name}
                            onChange={(e) =>
                              setEditingCourse({
                                ...editingCourse,
                                subject_name: e.target.value,
                              })
                            }
                          />
                        ) : (
                          course.subject_name
                        )}
                      </td>
                      <td>
                        {editingCourse &&
                        editingCourse.subject_id === course.subject_id ? (
                          <select
                            id="instructor"
                            value={editingCourse.instructor}
                            onChange={(e) =>
                              setEditingCourse({
                                ...editingCourse,
                                instructor: e.target.value,
                              })
                            }
                            required
                          >
                            <option value="">Select an Instructor</option>
                            {instructors.map((instructor) => (
                              <option key={instructor.id} value={instructor.id}>
                                {`${instructor.first_name} ${instructor.last_name}`}
                              </option>
                            ))}
                          </select>
                        ) : (
                          instructorName
                        )}
                      </td>
                      <td>
                        {editingCourse &&
                        editingCourse.subject_id === course.subject_id ? (
                          <button
                            onClick={() => handleSubmitChanges(editingCourse)}
                          >
                            Submit
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              handleEdit(course);
                              setSelectedInstructor(course.instructor);
                            }}
                          >
                            Edit
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CourseManage;
