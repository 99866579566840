import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Form,
  Button,
} from "react-bootstrap";
import "./styles_new.css";

function Signup() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    streetAddress: "",
    city: "",
    state: "Alabama",
    zipcode: "",
    role: "student",
  });

  const [validation, setValidation] = useState({
    firstNameValid: true,
    lastNameValid: true,
    emailValid: true,
    passwordValid: true,
    zipcodeValid: true,
  });

  // Validation functions for each field
  const validateFirstName = (name) => /^[a-zA-Z ]+$/.test(name);
  const validateLastName = (name) => /^[a-zA-Z ]+$/.test(name);
  const validateEmail = (email) =>
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
  const validatePassword = (password) => password.length >= 6;
  const validateZipcode = (zipcode) => /^\d{5}(-\d{4})?$/.test(zipcode);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    let isValid = true;
    switch (name) {
      case "firstName":
        isValid = validateFirstName(value);
        break;
      case "lastName":
        isValid = validateLastName(value);
        break;
      case "email":
        isValid = validateEmail(value);
        break;
      case "password":
        isValid = validatePassword(value);
        break;
      case "zipcode":
        isValid = validateZipcode(value);
        break;
      default:
        break;
    }
    setValidation({ ...validation, [`${name}Valid`]: isValid });
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const redirectToHome = () => {
    navigate("/");
  };

  const redirectToLogin = () => {
    navigate("/login");
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      firstNameValid,
      lastNameValid,
      emailValid,
      passwordValid,
      zipcodeValid,
    } = validation;
    if (
      !firstNameValid ||
      !lastNameValid ||
      !emailValid ||
      !passwordValid ||
      !zipcodeValid
    ) {
      alert("Please correct the errors before submitting.");
      return;
    }

    try {
      // Send the registration data to the PHP backend
      const response = await fetch("https://kxp7240.uta.cloud/php/signupEmail.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json(); // Assuming the server sends back JSON

      if (response.ok) {
        // If the registration was successful
        console.log(data.message); // Log the success message from the server
        navigate("/login"); // Redirect the user to the login page
      } else {
        // If there was an error with the registration
        console.error("Error during registration:", data.message); // Log the error message from the server
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <Navbar bg="light" expand="lg" className="modern-navbar custom-blue-nav">
        <Container>
          <Navbar.Brand onClick={() => navigate("/")}>Syllabix</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
              <Nav.Link onClick={() => navigate("/login")}>Log In</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="min-vh-100 d-flex align-items-center justify-content-center">
        <Row className="w-100">
          <Col md={6} className="mx-auto">
            <h1>Sign Up</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  required
                  value={formData.firstName}
                  onChange={handleInputChange}
                  isInvalid={!validation.firstNameValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid first name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  required
                  value={formData.lastName}
                  onChange={handleInputChange}
                  isInvalid={!validation.lastNameValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid last name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleInputChange}
                  isInvalid={!validation.emailValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  required
                  value={formData.password}
                  onChange={handleInputChange}
                  isInvalid={!validation.passwordValid}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a valid password.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formStreetAddress">
                <Form.Label>Street Address</Form.Label>
                <Form.Control
                  type="text"
                  name="streetAddress"
                  required
                  value={formData.streetAddress}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formCity">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="text"
                  name="city"
                  required
                  value={formData.city}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formState">
                <Form.Label>State</Form.Label>
                <Form.Control
                  as="select"
                  name="state"
                  required
                  value={formData.state}
                  onChange={handleInputChange}
                >
                  <option value="Alabama">Alabama</option>
                  <option value="Alaska">Alaska</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formZipcode">
                <Form.Label>Zipcode</Form.Label>
                <Form.Control
                  type="text"
                  name="zipcode"
                  required
                  value={formData.zipcode}
                  onChange={handleInputChange}
                />
              </Form.Group>

              <Form.Group controlId="formRole">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  as="select"
                  name="role"
                  required
                  value={formData.role}
                  onChange={handleInputChange}
                >
                  <option value="student">Student</option>
                  <option value="instructor">Instructor</option>
                  <option value="qaOfficer">QA Officer</option>
                  <option value="programCoordinator">
                    Program Coordinator
                  </option>
                </Form.Control>
              </Form.Group>

              <Button variant="primary" type="submit" className="w-100 mt-3">
                Sign Up
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Signup;
