import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  Table,
} from "react-bootstrap";
import "./styles_new.css";

function Admin() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [courses, setCourses] = useState([]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const redirectToLogOut = () => {
    sessionStorage.removeItem("Session_Login_Data");
    navigate("/login");
  };

  useEffect(() => {
    fetch("https://kxp7240.uta.cloud/php/getuser.php") // Replace with your endpoint if different
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setUsers(data.users);
        } else {
          console.error("Error fetching users:", data.message);
        }
      })
      .catch((error) => console.error("Error fetching users:", error));
  }, []);
  useEffect(() => {
    fetch("https://kxp7240.uta.cloud/php/getcourses.php") // Replace with your endpoint if different
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCourses(data.courses);
        } else {
          console.error("Error fetching courses:", data.message);
        }
      })
      .catch((error) => console.error("Error fetching courses:", error));
  }, []);

  const handleCourseCreation = () => {
    navigate("/createcourse");
  };
  const handleUserAccount = () => {
    navigate("/edituser");
  };
  const handleReportsAndAnalytics = () => {
    /* logic or navigation */
  };
  const handleCourseManagement = () => {
    navigate("/coursemanage");
  };
  const redirectToChat = () => {
    navigate("/chat");
  };
  const instructorIds = courses.map((course) => parseInt(course.instructor));
  const result = users.filter((user) => instructorIds.includes(user.id));

  console.log(result);

  return (
    <>
      <Navbar bg="light" expand="lg" className="modern-navbar custom-blue-nav">
        <Container>
          <Navbar.Brand onClick={() => navigate("/")}>Syllabix</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={() => navigate("/")}>Home</Nav.Link>
              <Nav.Link>Profile</Nav.Link>
              <Nav.Link onClick={redirectToChat}>Chat</Nav.Link>
              <Nav.Link onClick={redirectToLogOut}>Log Out</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className="mt-4 align-items-center justify-content-center">
        <Row className="w-100">
          <Col className="text-center">
            <h1>Admin Dashboard</h1>
            <div className="d-flex justify-content-center flex-wrap mb-3">
              <Button variant="primary" onClick={handleCourseCreation}>
                Course Creation
              </Button>
              <Button variant="primary" onClick={handleCourseManagement}>
                Course Management
              </Button>
              <Button variant="primary" onClick={handleUserAccount}>
                User Account Management
              </Button>
              <Button variant="primary" onClick={handleReportsAndAnalytics}>
                Generate Reports and Analytics
              </Button>
            </div>
            <h2>Users</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zipcode</th>
                  <th>Role</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.email}</td>
                    <td>{user.street_address}</td>
                    <td>{user.city}</td>
                    <td>{user.state}</td>
                    <td>{user.zipcode}</td>
                    <td>{user.role}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <h2>Courses</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Course</th>
                  <th>Instructor Name</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => {
                  // Find the instructor in the users array
                  const instructor = users.find(
                    (user) => user.id === course.instructor
                  );
                  // Get the instructor's full name, or a placeholder if not found
                  const instructorName = instructor
                    ? `${instructor.first_name} ${instructor.last_name}`
                    : "Instructor Not Found";

                  return (
                    <tr key={course.subject_id}>
                      <td>{course.subject_name}</td>
                      <td>{instructorName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Admin;
